import React from 'react'
import Social from './Social'
import PropTypes from 'prop-types'
import './teamcard.sass'

const TeamCard = ({name, description, position, picture, social}) => {

  return(
    <div className="columns is-centered is-mobile mb-3">
      <div className="column is-narrow member-card has-text-centered">
        <div className="is-uppercase has-text-weight-bold has-text-white">
          {name}
        </div>
        <img 
          className="member-image pt-3"
          src={picture}
          alt="Placeholder image" 
        />
        <div className="position-card has-text-weight-bold">
          {position}
        </div>
        <div className="has-text-white pb-2">
          {description}
        </div>
        <Social 
          social={social}
        />
      </div>
    </div>
  )
}

TeamCard.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  picture: PropTypes.string,
  social: PropTypes.object
}

export default TeamCard