import * as React from "react";
import Template from "../../components/Template";
import Footer from '../../components/Footer'
import '../../components/test.sass'
import TeamCard from "../../components/TeamCard";

type Member = {
  name: string,
  position: string,
  desc: string,
  pic?: string,
  social?: {
    home?: string,
    twitter?: string,
    linkedin?: string
  }
}

const members: Member[] = [
  {
    name: 'Abhinav Rai',
    position: 'Swiss Knife',
    desc: 'Vision | Product | Sales',
    pic: "/team/abhi_2.jpeg",
    social: {
      home: 'https://abhinavrai.com/',
      twitter: 'https://twitter.com/imabhinavrai',
      linkedin: 'https://www.linkedin.com/in/abhinav-rai-24a803a3/'
    }
  },
  {
    name: 'Aman',
    position: 'Dev Stallion',
    desc: 'Build | Tech | Product',
    pic: "/team/aman.jpeg",
    social: {
      home: 'https://onlyoneaman.medium.com',
      twitter: 'https://twitter.com/onlyoneaman',
      linkedin: 'https://www.linkedin.com/in/onlyoneaman'
    }
  },
  {
    name: 'Darin Menezes',
    position: 'Designer.',
    desc: 'Designer | Artist | Gamer',
    pic: "/team/darin.jpeg",
    social: {
      home: 'https://www.darinmenezes.com/',
      twitter: 'https://twitter.com/DarinMene',
      linkedin: 'https://www.linkedin.com/in/darin-menezes-b16b61179/?originalSubdomain=in'
    }
  },
  {
    name: 'Kabir Rai',
    position: 'Marketing',
    desc: 'Support | Growth | Gamer ',
    pic: "/team/kabir.jpeg",
    social: {
      home: '',
      twitter: 'https://twitter.com/KabirRa61060637',
      linkedin: 'https://www.linkedin.com/in/kabir-r-930ba9138'
    }
  },
  {
    name: 'Tanushree Nath',
    position: 'Designer',
    desc: 'Design | Art | Fashion ',
    pic: "/team/tanushree.jpg",
    social: {
      home: '',
      twitter: '',
      linkedin: ''
    }
  },
  {
    name: 'Dheeraj',
    position: 'Product Engineer',
    desc: 'Web | Code | Cricket',
    pic: "/team/dheeraj_right.png",
    social: {
      home: '',
      twitter: 'https://twitter.com/DheerajMsdian7',
      linkedin: 'https://www.linkedin.com/in/dheerajmsd7/'
    }
  }
]

export default function TeamPage() {

  return (
    <Template
      topPaddingLess
      activeTab={3}
      title="Team"
    >
      <div className="container mt-3">
        <div className="columns is-multiline">
          {members.map((member, index) => (
            <div className="column is-one-third-tablet" key={index}>
              {(index >= 3)? <React.Fragment> <br /> <br /></React.Fragment>  : <React.Fragment />}
              <TeamCard
                name={member.name}
                description={member.desc}
                position={member.position}
                picture={member.pic}
                social={member.social}
              />
            </div>
          ))}
        </div>
        <Footer />
      </div>
    </Template>
  )
}