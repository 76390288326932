import React from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {HomeOutlined, TwitterOutlined, LinkedinOutlined} from "@ant-design/icons"


library.add(fab);

const Social = ({social}) => {

  if(!social) return null

  return(
    <div className="social has-text-centered">
      {social.twitter && (
        <a target="_blank" href={social.twitter}>
          <TwitterOutlined className="fa-2x pr-2" />
        </a>
      )}
      {social.home && (
          <a target="_blank" href={social.home}>
            <HomeOutlined className="fa-2x pr-2" />
          </a>
      )}
      {social.linkedin && (
        <a target="_blank" href={social.linkedin}>
          <LinkedinOutlined className="fa-2x" />
        </a>
      )}
    </div>
  )
}

Social.propTypes = {
  twitter: PropTypes.string,
  home: PropTypes.string,
  linkedin: PropTypes.string
}

export function FontAwesome(props) {
  return <i className="fas fa-home"></i>
}

export default Social